import React, {Component} from 'react';
import './App.css';
import {GiftedChat, Send} from "react-web-gifted-chat";
import firebase from "firebase";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import { fab } from '@fortawesome/free-brands-svg-icons'
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons'
//https://github.com/firebase/firebaseui-web-react
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// import FullpageAccordion from "react-fullpage-accordion";

// library.add(fas)

// <!-- The core Firebase JS SDK is always required and must be listed first -->
// <script src="https://www.gstatic.com/firebasejs/7.14.2/firebase-app.js"></script>
//
// <!-- TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries -->
// <script src="https://www.gstatic.com/firebasejs/7.14.2/firebase-analytics.js"></script>

/***
 *
 * https://medium.com/@janromaniak/working-realtime-chat-in-react-in-less-than-20-minutes-335f9cf73d69
 */


var firebaseConfig = {
    apiKey: "AIzaSyDNH8Pn56DV7bWGzYf35dNRmKaGqgjRg_8",
    authDomain: "geoscout-firebase.firebaseapp.com",
    databaseURL: "https://geoscout-firebase.firebaseio.com",
    projectId: "geoscout-firebase",
    storageBucket: "geoscout-firebase.appspot.com",
    messagingSenderId: "391936313765",
    appId: "1:391936313765:web:173f4e7bd71c616ecb804c",
    measurementId: "G-BQ1EX7WKX1"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

firebase.auth().useDeviceLanguage(); // set with function
firebase.auth().languageCode = 'ru'; // set with string

// Google OAuth Client ID, needed to support One-tap sign-up.
// Set to null if One-tap sign-up is not supported.
var CLIENT_ID = 'YOUR_OAUTH_CLIENT_ID';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }


class App extends Component {

    constructor() {
        super();

        this.maxChatMsgsToLoad = 100;

        this.chatUser = {}
        this.state = {
            messages: [],
            chat_infos: [],
            cur_chat_info: {id: '-', name: "Чат не выбран"},
            user: {},
            isAuthenticated: false,
        };

        // Configure FirebaseUI.
        this.uiConfig = {
            // Popup signin flow rather than redirect flow.
            signInFlow: firebase.auth().isSignInWithEmailLink(window.location.href) ? 'redirect' : 'popup',
            // We will display Google and Facebook as auth providers.
            signInOptions: [
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                // firebase.auth.EmailAuthProvider.PROVIDER_ID,
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                    // Whether the display name should be displayed in Sign Up page.
                    requireDisplayName: true
                },
                // {
                //     provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                //     // Use email link authentication and do not require password.
                //     // Note this setting affects new users only.
                //     // For pre-existing users, they will still be prompted to provide their
                //     // passwords on sign-in.
                //     signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                //     // Allow the user the ability to complete sign-in cross device, including
                //     // the mobile apps specified in the ActionCodeSettings object below.
                //     forceSameDevice: false,
                //     // Used to define the optional firebase.auth.ActionCodeSettings if
                //     // additional state needs to be passed along request and whether to open
                //     // the link in a mobile app if it is installed.
                //
                //     // emailLinkSignIn: function() {
                //     //     return {
                //     //         // Additional state showPromo=1234 can be retrieved from URL on
                //     //         // sign-in completion in signInSuccess callback by checking
                //     //         // window.location.href.
                //     //         url: 'https://www.example.com/completeSignIn?showPromo=1234',
                //     //         // Custom FDL domain.
                //     //         dynamicLinkDomain: 'example.page.link',
                //     //         // Always true for email link sign-in.
                //     //         handleCodeInApp: true,
                //     //         // Whether to handle link in iOS app if installed.
                //     //         iOS: {
                //     //             bundleId: 'com.example.ios'
                //     //         },
                //     //         // Whether to handle link in Android app if opened in an Android
                //     //         // device.
                //     //         android: {
                //     //             packageName: 'com.example.android',
                //     //             installApp: true,
                //     //             minimumVersion: '12'
                //     //         }
                //     //     };
                //     // }
                // }
            ],
            // Terms of service url.
            tosUrl: 'https://www.google.com',
            // Privacy policy url.
            privacyPolicyUrl: 'https://www.google.com',
            credentialHelper: 'none',
            // CLIENT_ID && CLIENT_ID != 'YOUR_OAUTH_CLIENT_ID' ?
            //     firebaseui.auth.CredentialHelper.GOOGLE_YOLO :
            //     firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM,
            callbacks: {
                // Avoid redirects after sign-in.
                signInSuccessWithAuthResult: () => false
            }
        };
    }

    // render() {
    //     if (!this.state.isSignedIn) {
    //         return (
    //             <div>
    //                 <h1>My App</h1>
    //                 <p>Please sign-in:</p>
    //                 <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
    //             </div>
    //         );
    //     }
    //     return (
    //         <div>
    //             <h1>My App</h1>
    //             <p>Welcome {firebase.auth().currentUser.displayName}! You are now signed-in!</p>
    //             <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
    //         </div>
    //     );
    // }


    componentDidMount() {
        // Listen to the Firebase Auth state and set the local state
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => {
                this.setState({isSignedIn: !!user})

                if (user) {
                    console.debug(user);

                    firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function (idToken) {
                        // Send token to your backend via HTTPS
                        // ...

                        console.debug("user token:");
                        console.debug(idToken);
                    }).catch(function (error) {
                        // Handle error
                    });

                    //Once you have an ID token, you can send that JWT to your backend and validate it using the Firebase Admin SDK, or using a third-party JWT library if your server is written in a language which Firebase does not natively support.                console.debug("Auth user: ");

                    this.setState({isSignedIn: true, user});
                    this.chatUser = {
                        id: user.providerId + '_' + user.uid,
                        name: user.displayName,
                        avatar: user.photoURL
                    }

                    this.loadChatInfos();
                    if (this.state.chat_infos.length > 0)
                        this.state.cur_chat_info = this.state.chat_infos[0];
                    this.loadMessages();
                } else {
                    this.setState({isSignedIn: false, user: {}, messages: []});
                }
            }
        );

        // firebase.auth().onAuthStateChanged(user => {
        //     if (user) {
        //         console.debug(user);
        //
        //         firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
        //             // Send token to your backend via HTTPS
        //             // ...
        //
        //             console.debug("user token:");
        //             console.debug(idToken);
        //         }).catch(function(error) {
        //             // Handle error
        //         });
        //
        //         //Once you have an ID token, you can send that JWT to your backend and validate it using the Firebase Admin SDK, or using a third-party JWT library if your server is written in a language which Firebase does not natively support.                console.debug("Auth user: ");
        //
        //
        //         this.setState({isSignedIn: true, user});
        //         this.chatUser = {
        //             id: user.providerId + '_' + user.uid,
        //             name: user.displayName,
        //             avatar: user.photoURL
        //         }
        //
        //         this.loadChatInfos();
        //         if (this.state.chat_infos.length > 0)
        //             this.state.cur_chat_info= this.state.chat_infos[0];
        //         this.loadMessages();
        //     } else {
        //         this.setState({isSignedIn: false, user: {}, messages: []});
        //     }
        // });
    }


    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    loadChatInfos() {
        // TODO: filter by logged in user

        // https://howtofirebase.com/collection-queries-with-firebase-b95a0193745d
        console.warn('load chat infos');

        // https://docs-examples.firebaseio.com/rest/retrieving-data.json?shallow=true&print=pretty'
        // "https://geoscout-firebase.firebaseio.com/rest/chats.json?shallow=true"
        // firebase.database().ref('/chats/?shallow=true')
        //     .once('value', function(snapshot) {
        //         // do something with snapshot
        //         console.warn(snapshot.val());
        // });

        const on_chat_name_loaded = snap => {
            console.warn(snap);
            const name = snap.val();
            name.id = snap.key
            console.warn(name);
            // message.createdAt = message.createdAt.toISOString();
            const {chat_infos} = this.state;
            chat_infos.push(name);
            this.setState({chat_infos});

            // this.loadMessages();
        };

        firebase
            .database()
            .ref("/chats_infos/")
            // .ref("/chats").child(0).child("messages/")
            .limitToLast(this.maxChatMsgsToLoad)
            .on("child_added", on_chat_name_loaded);
    }

    loadMessages() {
        // reset
        const {messages} = this.state;
        messages.length = 0;

        const on_msg_loaded = snap => {
            const message = snap.val();
            message.id = snap.key;
            if (null != message.sentAt) {
                message.createdAt = Date.parse(message.sentAt);
            }
            // message.createdAt = message.createdAt.toISOString();
            const {messages} = this.state;
            messages.push(message);
            this.setState({messages});
        };

        console.warn('load messages of: ' + this.state.cur_chat_info.id);

        firebase
            .database()
            .ref('/chats/' + this.state.cur_chat_info.id + '/messages/')
            // .ref("/chats").child(0).child("messages/")
            .limitToLast(30)
            .on("child_added", on_msg_loaded);
    }

    onSend(messages) {
        for (const message of messages) {
            this.saveMessage(message);//.then(r => );
        }
    }

    saveMessage(message) {
        message.sentAt = new Date().toISOString();
        // message.createdAt = message.createdAt.toISOString();
        console.warn(message)
        console.warn(this.chatUser)
        return firebase
            .database()
            .ref('/chats/' + this.state.cur_chat_info.id + '/messages/')
            //.ref("/chats").child(0).child("messages/")
            .push(message)
            .catch(function (error) {
                console.error("Error saving message to Database:", error);
            });
    }

    async signIn() {
        const googleProvider = new firebase.auth.GoogleAuthProvider();
        try {
            await firebase.auth().signInWithPopup(googleProvider);
        } catch (error) {
            console.error(error);
        }
    }

    signOut() {
        firebase.auth().signOut();
    }

    deleteAccount() {
        if (!!firebase.auth().currentUser) {
            firebase.auth().currentUser.delete().catch(function (error) {
                if (error.code == 'auth/requires-recent-login') {
                    // The user's credential is too old. She needs to sign in again.
                    firebase.auth().signOut().then(function () {
                        // The timeout allows the message to be displayed after the UI has
                        // changed to the signed out state.
                        setTimeout(function () {
                            alert('Please sign in again to delete your account.');
                        }, 1);
                    });
                }
            });
        }
    };

    renderPopup() {

        return (
            <Dialog open={!this.state.isSignedIn}>
                <DialogTitle id="simple-dialog-title">Вход</DialogTitle>
                <div>
                    <List>
                        <ListItem>
                            <p>Войдите в аккаунт:</p>
                        </ListItem>

                        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
                    </List>
                </div>
            </Dialog>
        );


        // return (
        //     <Dialog open={!this.state.isSignedIn}>
        //         <DialogTitle id="simple-dialog-title">Вход</DialogTitle>
        //         <div>
        //             <List>
        //                 <ListItem button onClick={() => this.signIn()}>
        //                     <ListItemAvatar>
        //                         <Avatar style={{backgroundColor: "#eee"}}>
        //                             <img
        //                                 src={process.env.PUBLIC_URL + '/assets/images/Google_%22G%22_Logo.svg'}
        //                                 height="30"
        //                                 alt="G"
        //                             />
        //                         </Avatar>
        //                     </ListItemAvatar>
        //                     <ListItemText primary="Вход через Google"/>
        //                 </ListItem>
        //             </List>
        //         </div>
        //     </Dialog>
        // );
    }

    renderSignOutButton() {
        if (this.state.isSignedIn) {
            console.warn(this.state)

            return <Button onClick={() => this.signOut()}>Выход</Button>;
        }
        return null;
    }

    renderMainMapsRefButton() {
        return <Button onClick={() => window.open('https://spotswat.com/', '_blank')}>🗺️ Карты и навигация</Button>;
    }

    renderHr() {
        const ColoredLine = ({color}) => (
            <hr
                style={{
                    color: color,
                    backgroundColor: color,
                    height: 1,
                    width: 100
                }}
            />
        );

        return <ColoredLine color="black"/>
    }

    renderChat() {
        console.log("renderChat");
        return (
            <GiftedChat
                // TODO: get current chat and get messages for current chat
                user={this.chatUser}
                // showAvatarForEveryMessage={true}
                showUserAvatar={true}
                placeholder={"Введите сообщение..."}
                renderSend={this.renderSend}
                messages={this.state.messages.slice().reverse()}
                onSend={messages => this.onSend(messages)}
            />
        );
    }

    onChatListItemClick(chat_info) {
        // console.log("onChatListItemClick");
        // console.log(chat_info);
        // console.log(event.target);
        // console.log(event.currentTarget);

        // this.state.cur_chat_id =id
        // {cur_chat_id} = this.state;
        this.state.cur_chat_info = chat_info;
        this.setState();

        this.loadMessages();
    }

    renderChannels() {
        return (
            <List>
                {this.state.chat_infos.map((chat_info, i) => {
                    // console.log("renderChannels");
                    // console.log(chat_info);
                    // Return the element. Also pass key
                    return (
                        <ListItem button onClick={() => this.onChatListItemClick(chat_info)}>
                            <ListItemAvatar>
                                <Avatar>{chat_info.avatar}</Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={chat_info.name}/>
                        </ListItem>)
                })}
            </List>
        );
    }

    renderChannelsHeader() {
        return (
            <AppBar position="static" color="default">
                <Toolbar>
                    <Typography variant="h6" color="inherit">
                        Чаты
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }

    renderChatHeader() {
        return (
            <AppBar position="static" color="default">
                <Toolbar>
                    <Typography variant="h6" color="inherit">
                        {this.state.cur_chat_info.name}
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }

    renderSend(props) {
        return (
            <Send
                {...props}
            >
               <span style={{
                   border: '3px solid #00a6f5f2', borderRadius: '5px', width: '100px',
                   verticalAlign: 'middle', padding: '3px'
               }}>
              <FontAwesomeIcon icon={faPaperPlane}/>
                   <span
                       style={{display: 'inline-block'}}> Отправить</span> {
                   /*<FontAwesomeIcon icon={faSpinner} rotation={90}  />*/}
                </span>

            </Send>
        );
    }

    renderSettingsHeader() {
        return (
            <AppBar position="static" color="default">
                <Toolbar>
                    <Typography variant="h6" color="inherit">
                        Настройки
                    </Typography>
                </Toolbar>
            </AppBar>
        );
    }

    renderAccShort() {
        let currentUser = firebase.auth().currentUser;
        const isLoggedIn = !!currentUser; //this.state.isLoggedIn;
        let button;
        // if (isLoggedIn) {
        //     button = <LogoutButton onClick={this.handleLogoutClick} />;
        // } else {
        //     button = <LoginButton onClick={this.handleLoginClick} />;
        // }

        if (isLoggedIn) {
            var photoURL = ""
            if (currentUser.photoURL) {
                photoURL = currentUser.photoURL;
                // Append size to the photo URL for Google hosted images to avoid requesting
                // the image with its original resolution (using more bandwidth than needed)
                // when it is going to be presented in smaller size.
                if ((photoURL.indexOf('googleusercontent.com') != -1) ||
                    (photoURL.indexOf('ggpht.com') != -1)) {
                    photoURL = photoURL + '?sz=' + '32';
                    //document.getElementById('photo').clientHeight;
                }
                // document.getElementById('photo').src = photoURL;
                // document.getElementById('photo').style.display = 'block';
            } else {
                // document.getElementById('photo').style.display = 'none';
            }

            button = <div>
                <div id="user-info" style={{
                    fontSize: "0.675rem",
                    border: "1px solid #CCC",
                    clear: "both",
                    margin: "0 auto 10px",
                    minWidth: "200px",
                    maxWidth: "300px",
                    padding: "5px",
                    textAlign: "left"
                }}>
                    <div id="photo-container" style={{
                        backgroundColor: "#EEE",
                        border: "1px solid #CCC",
                        float: "left",
                        height: "32px",
                        marginRight: "10px",
                        width: "32px"
                    }}>
                        <img style={{display: "inline-block"}} src={photoURL} id="photo"/>
                    </div>
                    <div id="name">{currentUser.displayName}</div>
                    <div id="email">{currentUser.email}</div>
                    <div id="phone">{currentUser.phoneNumber}</div>
                    <div id="is-new-user"></div>
                    <div className="clearfix"></div>
                <br></br>
                    <Button style={{fontSize: "0.675rem", display: "inline-block"}}
                            onClick={() => this.signOut()}>Выход</Button>
                    <Button style={{fontSize: "0.675rem", display: "inline-block"}}
                            onClick={() => this.deleteAccount()}>Удалить
                        аккаунт</Button>

                </div>
            </div>;

            // document.getElementById('user-signed-in').style.display = 'block';
            // document.getElementById('user-signed-out').style.display = 'none';
            // document.getElementById('name').textContent = user.displayName;
            // document.getElementById('email').textContent = user.email;
            // document.getElementById('phone').textContent = user.phoneNumber;
            // if (user.photoURL) {
            //     var photoURL = user.photoURL;
            //     // Append size to the photo URL for Google hosted images to avoid requesting
            //     // the image with its original resolution (using more bandwidth than needed)
            //     // when it is going to be presented in smaller size.
            //     if ((photoURL.indexOf('googleusercontent.com') != -1) ||
            //         (photoURL.indexOf('ggpht.com') != -1)) {
            //         photoURL = photoURL + '?sz=' +
            //             document.getElementById('photo').clientHeight;
            //     }
            //     document.getElementById('photo').src = photoURL;
            //     document.getElementById('photo').style.display = 'block';
            // } else {
            //     document.getElementById('photo').style.display = 'none';
            // }
        } else {
            button = <p>Вы не вошли</p>;
        }

        return (
            <div> {button}</div>
        )
    }

    render() {
        return (
            <div style={styles.container}>
                {this.renderPopup()}
                <div style={styles.channelList}>
                    {this.renderChannelsHeader()}
                    {this.renderChannels()}
                    {this.renderHr()}
                    {this.renderAccShort()}
                    {/*{this.renderSignOutButton()}*/}
                    {this.renderMainMapsRefButton()}
                </div>
                <div style={styles.chat}>
                    {this.renderChatHeader()}
                    {this.renderChat()}
                </div>
                {/*<div style={styles.settings}>*/}
                {/*    {this.renderSettingsHeader()}*/}
                {/*    {this.renderSignOutButton()}*/}
                {/*</div>*/}
            </div>
        );
    }

    // demo_ref_public(){
    //     render() {
    //         // Note: this is an escape hatch and should be used sparingly!
    //         // Normally we recommend using `import` for getting asset URLs
    //         // as described in “Adding Images and Fonts” above this section.
    //         return <img src={process.env.PUBLIC_URL + '/img/logo.png'} />;
    //     }
    // }
}

const styles = {
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        width: "100vw",
    },
    channelList: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    chat: {
        display: "flex",
        flex: 3,
        flexDirection: "column",
        borderWidth: "1px",
        borderColor: "#ccc",
        borderRightStyle: "solid",
        borderLeftStyle: "solid",
    },
    settings: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
};


export default App;


//
// ReactDOM.render(<App />, document.getElementById("root"));
